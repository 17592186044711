import styled from 'styled-components';
import { media } from 'styles/utils';

export const PageWrapper = styled.div`
  margin: 0 auto;
  max-height: 100%;

  ${media.desktop`
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
  `}
`;

export const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 33% auto;
  grid-gap: 12px;
`;

export const ButtonContainer = styled.div`
  padding-bottom: 30px;
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.desktop`
    width: 60%;
  `}
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7.4vh;

  ${media.desktop`
    width: 60%;
  `}
`;
