import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { meDataSelector } from 'selectors/me';
import { useAuthenticationUser, useSelector } from 'services/hooks';

import { AccountForm } from './components';
import { Container } from './styled';

export const AccountPage = () => {
  const userData = useSelector(meDataSelector);
  const { determined, authenticated } = useAuthenticationUser();
  const { navigate } = useI18next();

  React.useEffect(() => {
    if (determined && !authenticated) {
      navigate('/');
    }
  }, [determined, authenticated]);

  if (!userData) {
    return null;
  }

  return (
    <Container>
      <AccountForm data={userData} />
    </Container>
  );
};
