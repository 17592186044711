import * as React from 'react';
import { graphql } from 'gatsby';
import { Router, useLocation } from '@reach/router';

import { Template } from 'common/layout';
import { PrivateRoute } from 'common/general';
import { AccountPage as Account } from 'modules/AccountPage';

const AccountPage = () => {
  const location = useLocation();

  return (
    <Template>
      <Router
        key={location.pathname}
        location={location}
        basepath="/:lang"
      >
        <PrivateRoute path="/account" component={Account} />
      </Router>
    </Template>
  );
};

export default AccountPage;

export const query = graphql`
  query AccountPage($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          language
          data
        }
      }
    }
  }
`;
